.root {
    & [class*='richText'] {
        line-height: var(--line-height-base);
        margin-bottom: var(--spacing-7);
        @media --md {
            margin-bottom: 0;
        }
    }
    
    &.singleColumn {
        & .headerAlignment {
            @media --md {
                max-width: 70ch;
                margin: 0 auto;
            }
        }
    }
}

.innerContainer {
    padding: var(--spacing-4) 0;
}

.withDarkBackground {
    color: white;
}

.withDarkBackground {
    color: white;
}

.headerAlignment {
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    flex-direction: column;
}

.columns {
    @media --md {
        display: grid;
        gap: var(--spacing-7);
        grid-auto-flow: column;
        justify-content: center;
    }
}